import React from "react"
import { graphql } from "gatsby"

import Mailto from "react-protected-mailto"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialSharing from "../components/socialsharing"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"

class Consult extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    /*
    <form action="https://formspree.io/email@domain.tld" method="POST">
  <input type="text" name="name">
  <input type="email" name="_replyto">
  <input type="submit" value="Send">
</form> 
*/

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Holiday and trip planning" />
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <div className="col-lg-8">
            <p>
              Hi,
            </p>
            <p>
              Do you feel like a break?
            </p>
            <p>
              I might be able to help with that, I know some great places to go. I'm a travel consultant who specialises
              in helping people travel. Great deals on holidays with hidden gem airports are my specialty.
            </p>
            <p>
              Let me know your email address and what you're interested in, I'll see what I can find and let you know.
            </p>
            </div>
              <div className="row">
                <div className="col-lg-8 col-sm-12">
                  <form
                    action="https://formspree.io/contact@isittimeto.com"
                    method="POST"
                  >
                    <div className="input-group fluid">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="input-group fluid">
                      <textarea
                        name="message" 
                        placeholder="Tell us a bit about you. What do you like to do? Do you have a budget? When are you thinking of travelling?"
                        style={{
                          width: '100%',
                          height: '200px'
                        }}
                      >
                      </textarea>
                    </div>
                    <button type="submit">Send</button>
                  </form>
                </div>
                
              </div>
              
          </div>
          <div className="col-lg-3 col-sm-12">
            <SocialSharing location={this.props.location} title={siteTitle} siteUrl={data.site.siteMetadata.siteUrl}/>
            <MailChimp />
            <PopularPosts />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Consult

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`